import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const Hint7 = () => (
  <Layout>
    <Header /> 

    <header className="masthead6">
     <br></br>
     <br></br>
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Hint #7</h1>
          <br></br>
          <br></br>
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
            I am rarely needed
            <br></br>
            just for a day.
            <br></br>
            <br></br>
            But at night you may seek me
            <br></br>
            from far far away.
            <br></br>
            <br></br>
            You are getting close
            <br></br>
            but not quite done.
            <br></br>
            <br></br>
            Go to this place
            <br></br>
            that begins with a One. 
          </h2>
          <br></br>
          <br></br>
          <h3 className="roll-in-bottom mx-auto">
          Distance: 2.7 miles North</h3>
          <br></br>
          <a href="/clue7" className="btn btn-primary">
              Back to Clue
            </a>
            <br></br>
            <br></br>
        </div>
      </div>
    </header>

    {/* <Clues />  

    <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Hint7;
